export default {
	"datatable.to": "bis",
	"datatable.of": "von",
	"datatable.pageSize": "Zeilen pro Seite:",
	"datatable.prevPage": "Vorherige Seite",
	"datatable.nextPage": "Nächste Seite",
	"datatable.selectAll": "Alle auswählen",
	"datatable.select": "wählen",
	"datatable.search": "Alle Spalten durchsuchen...",
	"datatable.selectedItems": "({selected}/{all}) ausgewählte Verträge",
	"datatable.documents": "Versicherungsrelevante Dokumente einsehen",
	"datatable.search.button": "Suchen",
	"datatable.policies": "Versicherungen",
	"datatable.claims": "Schäden",
	"datatable.createdAt": "Gemeldet am",
	"datatable.claimNumber": "Schadensnr.",
	"datatable.policyNumber": "Vertrag Nr.",
	"datatable.objectId": "Objekt-ID",
	"datatable.account": "Versicherungsnehmer",
	"datatable.claims.empty": "Sie haben keine Schäden gemeldet",
	"datatable.leads.empty": "Sie haben noch keine Versicherung abgeschlossen.",
	"datatable.add": "Neue Versicherung hinzufügen",
	"datatable.type": "Schadensart",
	"datatable.claims.notFound": "Keine Schäden gefunden",
	"datatable.leads.notFound": "Keine Verträge gefunden",
} as const;
